import React from 'react'
import { Container, Heading, Link, Text } from 'rebass'
import { Header, NavBar } from '../components'
import Layout from '../layouts/default'

export default () => (
  <Layout>
    <NavBar />
    <Header height='40vh' image='/assets/img/banners/macbook-desk-1200x900.jpg'>
      <Heading is='h1' fontSize={[4, 5, 6]} fontWeight='300' my={3}>Donate</Heading>
    </Header>
    <Container m={2}>
        <Heading is='h2' fontSize={[3, 4, 5]} fontWeight='300' my={3}>Donate</Heading>
        <Text my={2} fontSize={[0, 1]} fontWeight='300'>
            If you or your company are using any of my projects, consider supporting me so I can continue my free open source work.
        </Text>
        <Heading is='h3' fontSize={[2, 3, 4]} fontWeight='300' my={3}>Monthly donations</Heading>
        <Text my={2} fontSize={[0, 1]} fontWeight='300'>
            <ul>
                <li><Link href='https://github.com/sponsors/ovhemert' target='_self' rel='noopener noreferrer' mx={2} title='GitHub Sponsors'>GitHub Sponsors</Link></li>
                <li><Link href='https://www.patreon.com/ovhemert' target='_blank' rel='noopener noreferrer' mx={2} title='Patreon'>Patreon</Link></li>
            </ul>
        </Text>
        <Heading is='h3' fontSize={[2, 3, 4]} fontWeight='300' my={3}>One-time donations</Heading>
        <Text my={2} fontSize={[0, 1]} fontWeight='300'>
            <ul>
                <li><Link href='https://commerce.coinbase.com/checkout/fd177bf0-a89a-481b-889e-22bfce857b75' target='_blank' rel='noopener noreferrer' mx={2} title='Coins'>Coins</Link></li>
                <li><Link href='https://www.paypal.me/osmondvanhemert' target='_blank' rel='noopener noreferrer' mx={2} title='PayPal'>PayPal</Link></li>
                <li><Link href='http://ko-fi.com/ovhemert' target='_blank' rel='noopener noreferrer' mx={2} title='Ko-fi'>Ko-fi</Link></li>
            </ul>
        </Text>
    </Container>
  </Layout>
)